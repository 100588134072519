<template>
   <div>
     <v-banner
        single-line
        height="500"
        class="banner"
    >
       <v-img
            src="https://h5.ophyer.cn/official_website/banner/newlinshou_banner01.png"
            height="100%"
            class="d-flex align-center"
            >
            <v-container color="transparent" class="px-6 max-width-1200 height-per-100 d-flex align-center">
              <div class="flex-grow-1">
                <v-card color="transparent" class="ml-0" flat>
                  <v-card-title class="pa-0 font-size-banner-title family-Bold">
                    <div>
                      <div class="pt-2 color-FFF" style="font-weight: 500; ">VR云店</div>
                    </div>
                  </v-card-title>
                  <v-card-title class="pa-0 font-size-banner-tip mt-8 color-FFF" style="line-height: 1.2">
                     VR云店依托于VR全景、360商品展示，商品热点、优惠券热点，<br/>助力电商零售行业提升销售转化
                  </v-card-title>
                </v-card>
              </div>
              <div class="flex-grow-1">
                <v-img src="https://h5.ophyer.cn/official_website/banner/newlinshou_banner02-1.png" width="90%"></v-img>
              </div>
            </v-container>
          </v-img>
    </v-banner>
    <!-- <v-container class="containerStyle max-width-1200" height='400' >
      <v-card class="wrap part-one d-flex justify-center" flat>
        <div class="pr-10 borderimg" >
          <img src="https://h5.ophyer.cn/official_website/other/Online1.png"/>
        </div>
        <div class="text-wrap" style="padding-right:160px">
          <v-card class="pa-0" flat>
            <v-card-title  class="con_tit pa-0 mb-5">720°全景店铺打破空间限制，重塑购物体验</v-card-title>
            <v-card-text  class="con_text pa-0">720°沉浸式虚拟全景店铺，添加商品/优惠券热点/360商品展示<br/>，提升用户真实体验感与消费舒适度，带来全新的购物体验</v-card-text>
          </v-card>
        </div>
      </v-card>
      <v-card class="wrap part-one d-flex flex-no-wrap justify-center flex-row-reverse" flat>
        <div class="pl-10 borderimg_two">
          <img src="https://h5.ophyer.cn/official_website/other/Online2.png" />
        </div>
        <div class="text-wrap" style="padding-left:160px">
          <v-card class="pa-0"  flat>
            <v-card-title class="con_tit pa-0 mb-5" >结合互动游戏实现运营效果翻倍</v-card-title>
            <v-card-text class="con_text pa-0">利用AR趣味互动游戏结合实物、优惠券/卡密、积分等多种<br/>奖励方式，轻松获取促进下单，满足商家引流获客客</v-card-text>
          </v-card>
        </div>
      </v-card>
      <v-card class="wrap part-one d-flex justify-center" flat>
        <div class="pr-10 borderimg">
          <img src="https://h5.ophyer.cn/official_website/other/Online3.png" />
        </div>
        <div class="text-wrap" style="padding-right:160px">
          <v-card class="pa-0" flat>
            <v-card-title  class="con_tit pa-0 mb-5">360°全方位展示,商品信息更直观</v-card-title>
            <v-card-text  class="con_text pa-0">360°全方位展示商品，让您的商品纤毫毕现。给用户更真实<br/>的细节展示，促进用户下单</v-card-text>
          </v-card>
        </div>
      </v-card>
      <v-card class="wrap part-one d-flex flex-no-wrap justify-center flex-row-reverse" flat>
        <div class="pl-10 borderimg_two">
          <img src="https://h5.ophyer.cn/official_website/other/Online4.png" />
        </div>
        <div class="text-wrap" style="padding-left:160px">
          <v-card class="pa-0"  flat>
            <v-card-title class="con_tit pa-0 mb-5" >多平台一键卖货</v-card-title>
            <v-card-text class="con_text pa-0">微信、QQ、头条、抖音等多平台，一键卖货<br/>全网引流</v-card-text>
          </v-card>
        </div>
      </v-card>
      <v-card class="wrap part-one d-flex justify-center" flat>
        <div class="pr-10 borderimg">
          <img src="https://h5.ophyer.cn/official_website/other/Online5.png" />
        </div>
        <div class="text-wrap" style="padding-right:160px">
          <v-card class="pa-0" flat>
            <v-card-title  class="con_tit pa-0 mb-5">支持个性化定制满足多样化需求</v-card-title>
            <v-card-text  class="con_text pa-0">专属电商解决方案顾问1对1服务，支持定制开发，满<br/>足差异化需求</v-card-text>
          </v-card>
        </div>
      </v-card>
    </v-container> -->

    <!-- 功能 -->
    <v-card class="warp warp2" :flat="true">
      <div class="public-title">
        <p>APAAS ENGINE </p>
        <div>
          <span>功能</span>
        </div>
      </div>
      <v-tabs v-model="tab" centered color="#0084ff" class="g-tabs mt-8">
        <v-tab v-for="(v, k) in list" :key="k">
          <b>{{ v.title }}</b>
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item v-for="(v, k) in list" :key="k" transition="fade-transition">
          <div class="box">
            <transition name="slide-fade" appear >
              <div class="left" v-show="tab == k"><img :src="v.img" /></div>
            </transition>
            <transition name="slide-fade-reverse" appear >
              <div class="right" v-show="tab == k">
                <v-card-text class="title" v-text="v.h2" />
                <div class="line"></div>
                <v-card-text class="sub" v-text="v.sub" />
                <v-card-text class="tip" v-text="v.content" v-html="v.content" />
              </div>
            </transition>
          </div>
        </v-tab-item>
      </v-tabs-items>
    </v-card>

    <!-- 方案优势 -->
    <v-card class="warps warp1" :flat="true">
      <div class="public-title">
        <p>APAAS ENGINE</p>
        <div>
          <span>方案优势</span>
        </div>
      </div>
      <div class="con">
        <v-row no-gutters justify='space-between'>
          <v-col v-for="(v, k) in Vorteil" :key="k" cols="12" sm="3" >
            <div class="box">
              <img :src='v.img' />
              <v-card-title> {{ v.title }} </v-card-title>
              <v-card-text class="tip"> {{ v.text }} </v-card-text>
            </div>
          </v-col>
        </v-row>
      </div>
    </v-card>
    <!-- 精彩案列 -->
    <v-container class="justify-center mb-6 wap_case" >
     <div class="public-title">
       <p>WONDERFUL CASE</p>
       <div>
          <span>推荐案例</span>
       </div>
     </div>
     <v-container style="max-width:1200px;margin-top:62px">
           <v-row>
               <v-col v-for="(card, index) in anli" :key="index" cols="3" class="mt-4">
                   <v-card
                      class="mx-auto item-card"
                      max-width="240"
                      max-height="410"
                      color="transparent"
                      @click="goCaseDetail(card)"
                    >
                      <v-img
                        max-height="340"
                        :src="card.coverImg"
                        :aspect-ratio="9/16"
                      >
                      <template v-slot:placeholder>
                              <v-row class="fill-height ma-0" align="center" justify="center">
                                <v-progress-circular
                                  indeterminate
                                  size="80"
                                  color="red lighten-5"
                                ></v-progress-circular>
                              </v-row>
                      </template>
                      </v-img>
                      <v-card-text class="font-size-14 font-weight-bold" v-text="card.itemTitle"></v-card-text>
                    </v-card>
                </v-col>
            </v-row>
      </v-container>
    </v-container>
    
   </div>
</template>

<script>
import textData from '@/static/textData.js' // 合作案例
import qs from 'qs'
export default {
  name: "OnlineRetailers",
  data(){
    return{
      model: 0,
      bannerLoad: false,
      imgLoad: false,
      windowHight: window.innerHeight/2,
      tab:null,
      list:[
        {title:"VR全景",h2:'VR展厅',sub:'沉浸式交互体验，全面展示品牌信息',img:'https://h5.ophyer.cn/official_website/other/xinshou1-1.png',content:'• 720°可交互全景，为您量身打造线上虚拟展厅<br/>• 全景编辑制作平台，简单操作即可生成场景'},
        {title:"360环物",h2:'360环物',sub:'贴合电商场景，让产品“动”起来',img:'https://h5.ophyer.cn/official_website/other/xinshou1-2.png',content:'• 简单拍摄即可生成360环物<br/>• 打破传统的静态图片展示，更多细节展示'},
        {title:"商品热点",h2:'商品热点',sub:'点击即可查看商品详情',img:'https://h5.ophyer.cn/official_website/other/xinshou1-3.png',content:'• 支持添加多张图片轮播展<br/>• 可添加多个产品规格<br/>• 可以添加商品列表展示同系列多个产品'},
        {title:"优惠券热点",h2:'优惠券热点',sub:'快速发放优惠券 助力销售转化',img:'https://h5.ophyer.cn/official_website/other/xinshou1-4.png',content:'• 自定义优惠券内容<br/>• 在场景内即可核销'},
      ],
      Vorteil:[
        {
          img:  require("../../../public/img/icons/DigitalMuseum/newretail1.png"),
          title: '打通各大电商平台',
          text: '一键卖货 全网引流'
        },
        {
          img: require("../../../public/img/icons/DigitalMuseum/newretail2.png"),
          title: '多场景自由切换',
          text: '支持添加多个场景店铺再大也能装下'
        },
        {
          img: require("../../../public/img/icons/DigitalMuseum/newretail3.png"),
          title: '智慧营销',
          text: '营销组件 助力智慧营销'
        },
        {
          img: require("../../../public/img/icons/DigitalMuseum/newretail4.png"),
          title: '二次开发',
          text: '可以导出离线包自定义开发'
        }
     ],
     anli:[textData.anli.sanxingshoujiquanjingdian,textData.anli.kidsyundian,textData.anli.xingyehongbaoyu,textData.anli.wanshifujiaju],
    }
  },
  mounted(){
    this.bannerLoad = true;
  },
  methods: {
    handleLoad (){
      this.imgLoad = true;
    },
    goCaseDetail:(item) =>{
      let query = {
        source: 'qita',
        anli: item.self
      }
      let url = '/CaseDetail?'+qs.stringify(query)
      window.open(url, '_blank')
    }
  }
}
</script>

<style scoped lang='scss'>


.banner{
  position: relative;
  ::v-deep .v-banner__wrapper,::v-deep .v-banner__content,::v-deep .v-banner__text{
    padding: 0 !important;
    height: 100%;
  }
}
.public-title{
  height: 0;
  margin: 65px auto 65px;
}
.banner_title{
  font-size: 60px;
  //font-family:$bold-font-family;
  color: #FFFFFF !important;
  // padding-top: 21%;
}
.scence_text{
  color: #FFFFFF !important;
  line-height: 26px !important;
}
.banner_span{
  //font-family: $normal-font-family;
  color: #2672FF !important;
  font-size: 20px;
}
::v-deep .v-banner__wrapper{
  padding: 0 !important;
}
.first_card{
  margin-top: 80px;
}
.containerStyle{
  margin-top: 80px;
  .v-card__title{
    line-height: 26px !important;
  }
  .borderimg{
    width:600px;
    height:300px;
    img{
      border-radius: 10px;
      float: right;
    }
  }
  .borderimg_two{
    width:600px;
    height:300px;
    img{
      border-radius: 10px;
      float: left;
    }
  }
  .part-one{
    margin-bottom: 21px;
    .text-wrap{
      width: 600px;
      .con_tit{
        font-size: 24px;
        font-weight: bold;
        color: #333333;
        line-height: 30px;
        margin-top:84px
      }
      .con_text{
        height: 40px;
        font-size: 14px;
        font-weight: 400;
        color: #666;
        line-height: 26px;
      }
      .banner-btn {
        margin-top: 50px;
        width: 160px;
        height: 50px;
        background-color: #0568FD;
        border-radius: 25px;
        font-size: 20px !important;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 50px;
        text-align: center;
        cursor: pointer;
      }
    }
  }
}
.titleh1{
  font-size: 80px;
  //font-family: $bold-font-family;
  line-height: 26px !important;
}
.spantitle{
  font-size: 24px;
  //font-family: $body-font-family;
  line-height: 26px !important;
}
.suntitle{
  font-size: 30px;
  //font-family: $bold-font-family;
  // font-weight: bold;
  font-style: italic;
  line-height: 48px !important;
}
.ptitle{
  font-size: 14px;
  font-family: $body-font-family;
  // font-weight: 400;
  line-height: 26px !important;
}

::v-deep .v-carousel__controls{
  display: none !important;
}
.item-card {
  margin-bottom: 15px;
  padding: 10px;
  box-shadow: 0px 10px 20px 0px rgba(0, 60, 255, 0.1);
  border-radius: 8px;
  &::after {
      content: "";
      background-image: url("../../assets/images/home/case-icon.png");
      background-size: 100%;
      width: 20px;
      height: 20px;
      position: absolute;
      bottom: 16px;
      right: 10px;
  }
  transition: 0.3s;
  &:hover {
      transform: scale(1.04);
      box-shadow: 0px 10px 20px 0px rgba(0, 60, 255, 0.1) !important;
  }
  &:focus{
     display: none;
  }
}
.wap_case{
  .public-title{
    margin: 45px auto 65px !important;
  }
}
.warp {
  margin: 60px auto 0;
  text-align: center;
}
.warp1 {
  background: #FBFBFB;
  padding: 1px 0 40px;
  margin-top: 40px;
  .con {
    width: 1080px;
    margin: 20px auto 0;
  }
  .box {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: column;
    text-align: center;
    img {
      width: 120px;
      height: 120px;
    }
  }
  .tip {
    width: 266px;
    opacity: 0.6;
  }
}
.warp2 {
  .box {
    width: 1024px;
    margin: 50px auto 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .left {
    margin-right: 55px;
    img {
      width: 400px;
      height: 260px;
      border-radius: 30px;
      object-fit: cover
    }
  }
  .right {
    text-align: start;
    //font-family: SourceHanSansCN-Regular;
    padding-top: 30px;
    .v-card__text {
      padding: 0;
    }
    .title {
      font-family: Source Han Sans CN;
      font-size: 24px !important;
      font-weight: 600 !important;
    }
    .sub{
      font-size: 14px;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      font-weight: 400;
      color: #000000;
      line-height: 18px;
      margin-top: 16px;
    }
    .line{
      width: 68px;
      height: 4px;
      background: #000000;
      margin-top: 5px;
    }
    .tip {
      margin: 10px 0;
      font-size: 16px !important;
      color: rgb(102, 102, 102) !important;
    }
    .text {
      font-size: 14px;
      line-height: 26px;
    }
  }
}
</style>